
/* MENU 3
--------------------------------------------------------------------------------------------*/

[data-m-type="menu-3"] {

    position: relative;
    background: $ac-menu-3-background;

    @include breakpoint(medium-portrait down) {
        width: 100%;
        z-index: $ac-menu-3-z-index;
        @if $ac-menu-3-mobile-fixed == true {
            position: fixed;
            box-shadow: $default-box-shadow;
        } @else {
            position: absolute;
        }
    }

    @if $ac-menu-3-gradient-before == true {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba($ac-menu-3-gradient-before-color, 0), rgba($ac-menu-3-gradient-before-color, 1));
            z-index: 1;
            @include breakpoint(medium-portrait down) {
                display: none;
            }
        }
    }

    @include breakpoint(medium) {
        @if $ac-menu-3-transparent == true {
            position: absolute;
            background: transparent;
            z-index: $ac-menu-3-z-index;
            width: 100%;
        }
    }

    ul.menu.dropdown {
        list-style: none;
        margin: 0;
        display: flex;

        a {
            margin-right: $default-padding;
        }

        li {
            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    ul.is-dropdown-submenu {
        list-style: none;
        margin: 0;
        padding: .4em 0;
        li.is-submenu-item {
            line-height: 2;
        }
        a {
            width: 100%;
            display: inline-block;
            padding: 0 1em;
            color: $default-text-color-dark;
        }
    }

    a {
        white-space: nowrap;
        margin: 0 .4em;
        svg {
            width: 1em;
            height: 1em;
        }
    }

    & + .main-container {
        @include breakpoint(medium-portrait down) {
            padding-top: $ac-menu-3-mobile-height;
        }
    }
}



@if $ac-menu-3-transparent-on-home == true {
    body.home {
        [data-m-type="menu-3"] {
            
            background-color: transparent;

            @include breakpoint(medium) {
                position: absolute;
                background: transparent;
                z-index: $ac-menu-3-z-index;
                width: 100%;
            }
            .ac_menu-3_bar_top,
            .ac_menu-3_main,
            .ac_menu-3_bar_bottom { 
                background-color: transparent;
            }
            * {
                color: $default-text-color-light;
            }
            .is-stuck {
                * {
                    color: initial;
                }
            }
        }
    }
}


//
//     MENU DIVDER
//

.ac_menu-3_divider {
    position: relative;
    padding: 0 1em;
    height: $ac-menu-3-divider-height;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        height: 100%;
        width: 2px;
        border-right: solid 1px rgba($default-text-color-dark, .1);
    }
}

//
//      STICKY
//

// .ac_menu-3_unsticky_container {
//     width: 100%;
//     height: 2px;
// }
.ac_menu-3_sticky_container {
    margin: 0 auto;
    transition: height .3s ease;
}
.ac_menu-3_sticky {
    width: 100%;
    z-index:  $ac-menu-3-z-index - 1;
    transition: background-color .3s ease;
    
    &.is-stuck {
        background-color: $ac-menu-3-background;
        z-index:  $ac-menu-3-z-index;
        box-shadow: $default-box-shadow;

        //animate elements on stuck
        .ac_menu-3_logo_img {
            width: $ac-menu-3-logo-width * .7;
        }
    }
    @include breakpoint(medium-portrait down) {
        display: none;
    }
}


//
//     LOGO
//

.ac_menu-3_logo_container {
    padding: $default-padding 0;
    @include breakpoint(medium-portrait down) {
        padding: 0;
    }
    @include breakpoint(medium) {
        flex: 0 0 $ac-menu-3-logo-width;
        margin-right: $default-padding;
    }
}
a.ac_menu-3_logo_link {
    will-change: contents;
    transition: opacity .3s ease;
    margin: 0;
    &:hover {
        opacity: .8;
    }
}
.ac_menu-3_logo_img {
    @include breakpoint(medium-portrait down) {
        height: $ac-menu-3-mobile-height - ( $ac-menu-3-mobile-padding * 2);
        width: initial;
    }
    width: $ac-menu-3-logo-width;
    transition: width .3s ease;
}



//
//      MAIN
//

.ac_menu-3_main {
    position: relative;
    margin: 0 auto;
    display: flex;
    z-index: $ac-menu-3-z-index;
    padding: 0 $default-padding;
    max-width: $ac-menu-3-content-width;
    
    @if $ac-menu-3-transparent == false {
        background: $ac-menu-3-main-background;
    }

    @include breakpoint(medium-portrait down) {
        padding: $default-padding / 2;
    }
}
.ac_menu-3_main_container {
    @include breakpoint(medium) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .3s ease, visibility 0s .6s;
    }
}
.ac_menu-3_main_content {
    display: flex;
    justify-content: flex-end;
    align-items: center; // baseline
    @include breakpoint(medium-portrait down) {
        display: none;
    }
}

//
//     BAR BOTTOM
//
.ac_menu-3_bar_bottom {
        @include breakpoint(medium-portrait down) {
        display: none;
    }
    @if $ac-menu-3-bar-bottom-enabled == false {
        display: none;
    }
}
@if $ac-menu-3-bar-bottom-enabled == true {
    .ac_menu-3_bar_bottom {
        width: 100%;
        padding: $default-padding / 2 $default-padding;
        position: relative;
        z-index: $ac-menu-3-z-index + 1;
        @if $ac-menu-3-transparent == false {
            background: $ac-menu-3-bar-bottom-background;
        }
    }
    .ac_menu-3_bar_bottom_container {
        padding: 0 $default-padding;
        max-width: $ac-menu-3-content-width;
        margin: 0 auto;
        display: flex;
        justify-content: $ac-menu-3-bar-bottom-justify-content;
    }
    .ac_menu-3_bar_bottom_content {
        line-height: 2;
    }
}



//
//     MOBILE CONTENT
//

.ac_menu-3_mobile_content {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include breakpoint(medium) {
        display: none;
    }
}
.ac_menu-3_mobile_content_divider {
    width: 1px;
    margin: 0 1em;
    background: rgba($default-text-color-dark, .1);
}



//
//     MOBILE BUTTONS
//

.ac_menu-3_mobile_btn {
	height: $ac-menu-3-mobile-height - ($ac-menu-3-mobile-padding * 2);
    width: $ac-menu-3-mobile-svg-size + $ac-menu-3-mobile-svg-size;
    padding: (($ac-menu-3-mobile-height - ($ac-menu-3-mobile-padding * 2) - $ac-menu-3-mobile-svg-size) / 2) $ac-menu-3-mobile-svg-size / 2;

    svg {
        width: $ac-menu-3-mobile-svg-size !important;
        height: $ac-menu-3-mobile-svg-size !important;
        fill: $ac-menu-3-mobile-svg-color;
    }

}


//
//     HAMBURGER ICON
//

.ac_menu-3_mobile_btn-hamburger {
    height: $ac-menu-3-mobile-height - ($ac-menu-3-mobile-padding * 2);
    width: $ac-menu-3-mobile-svg-size + $ac-menu-3-mobile-svg-size;
    padding: 12px $ac-menu-3-mobile-padding; // padding is manual

	.burger-icon {
		@include burger(25px, 2px, 4px, $default-icon-color); // width is manual
	}

	@include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .burger-icon {
		@include burger-to-cross;
	}
}



//
//     OVERLAY 
//

.ac_menu-3_overlay_container {
    position: fixed;
    z-index: $ac-menu-3-z-index - 1;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    background: $ac-menu-3-overlay-container-background;
    transform: translateX(400px);
    transition: transform .3s ease;
    padding-top: $ac-menu-3-mobile-height;

    @include breakpoint(small only) {
        width: 90vw;
        transform: translateX(100%);
    }
    @include breakpoint(medium) {
		display: none;
	}
    
}
.ac_menu-3_overlay_scroll_container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
}
.ac_menu-3_overlay_scroll_container_inner {
    min-height: 100vh;
}
.ac_menu-3_overlay_menu_container {
    padding: $default-padding;
}
ul.ac_menu-3_overlay_menu_primary {
    list-style: none;
    margin: 0;
    @if $ac-menu-3-overlay-menu-primary-border != none {
        border-top: $ac-menu-3-overlay-menu-primary-border;
    }
    padding-bottom: 2em;

    a {
        display: block;
        text-align: $ac-menu-3-overlay-menu-primary-text-align;
    }

    > li {
        @if $ac-menu-3-overlay-menu-primary-border != none {
            border-bottom: $ac-menu-3-overlay-menu-primary-border;
        }
        > a {
            padding: $default-padding / 2 0;
        }
    }
    ul.is-accordion-submenu {
        list-style: none;
        margin-left: 1em;
        margin-bottom: 1em;
        a {
            padding: $default-padding / 4 0;
        }
    }
}

ul.ac_menu-3_overlay_menu_secondary {
    list-style: none;
    margin: 0;
    @if $ac-menu-3-overlay-menu-secondary-border != none {
        border-top: $ac-menu-3-overlay-menu-secondary-border;
    }
    a {
        display: block;
        @if $ac-menu-3-overlay-menu-secondary-border != none {
            border-bottom: $ac-menu-3-overlay-menu-secondary-border;
        }
        text-align: $ac-menu-3-overlay-menu-secondary-text-align;
        padding: $default-padding / 4 0;
    }
}


body.s_is-open_menu-mobile {
    .ac_menu-3_overlay_container {
        transform: translateX(0);
    }
    overflow: hidden;
}

//
//     OVERLAY BACKDROP
//


.ac_menu-3_overlay_backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $ac-menu-3-overlay-backdrop-background;
    z-index: $ac-menu-3-z-index - 2;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease .3s, height 0s .6s;

    @include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .ac_menu-3_overlay_backdrop {
        height: 100%;
        opacity: 1;
        transition: height 0s, opacity .3s ease;
    }
}



//
//     SEARCH
//


.ac_menu-3_search_container {
    position: absolute;
    top: 50%; 
    right: 0;
    width: 50%;
    transform: translateY(-50%) translateX(-2em);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    @include breakpoint(medium-portrait down) {
        top: 50%;
        right: 1em;
    }
}
body.IE10 {
    .ac_menu-3_search_container {
        display: none;
    }
}

.ac_menu-3_search_input {
    margin: 0;
}
.ac_menu-3_search_close {
    position: absolute;
    right: .7em;
    top: 0;
    width: 3em;
    height: 3em;
    padding: .7em;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $ac-menu-3-mobile-svg-color;
    }
}

body.s_is-open_menu-search {
    &.IE10 { 
        .ac_menu-3_search_container {
            display: block;
        }
    }
    .ac_menu-3_search_container {
        transform: translateX(0) translateY(-50%);
        opacity: 1;
        pointer-events: auto;
        @include breakpoint(medium-portrait down) {
            transform: translateX(0) translateY(-50%);
        }
    }
}


//
//     SOCIALS
//


.ac_socials_link_icon {
    background: $ac-menu-3-socials-icon-background;
    svg {
        fill:  $ac-menu-3-socials-icon-color;
    }
    &:hover {
        @include breakpoint(large) {
            background: $ac-menu-3-socials-icon-hover-background;
            svg {
                fill: $ac-menu-3-socials-icon-hover-color;
            }
        }
    }
}