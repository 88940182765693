
/* PART - CONTENT
--------------------------------------------------------------------------------------------*/
.ac_content {
    position: relative;
}
.ac_content_container {
    position: relative;
    z-index: 1;
    max-width: $max-content-1000;
    margin: 0 auto;
    padding: 0 $default-padding;

    &.a_collapsed {
        padding: 0;
    }
}
.ac_content_flex {
    padding: 0 $default-padding / 2;
    @include breakpoint(medium) {
        display: flex;
        padding: 0 $default-padding / 2;
    }
}


.ac_content_1-1,
.ac_content_full,
.ac_content_fullwidth {
    padding: 0 $default-padding / 2;
    width: 100%;
}

.ac_content_half,
.ac_content_1-2 {    
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(1/2);
        max-width: percentage(1/2);
    }
}

.ac_content_1-3 {
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(1/3);
        max-width: percentage(1/3);
    }
}
.ac_content_2-3 {
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(2/3);
        max-width: percentage(2/3);
    }
}

.ac_content_1-4 {
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(1/4);
        max-width: percentage(1/4);
    }
}
.ac_content_3-4 {
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(3/4);
        max-width: percentage(3/4);
    }
}

.ac_content_1-5 {
    padding: 0 $default-padding / 2;
        width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(1/5);
        max-width: percentage(2/5);
    }
}
.ac_content_2-5 {
    padding: 0 $default-padding / 2;
        width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(2/5);
        max-width: percentage(2/5);
    }
}
.ac_content_3-5 {
    padding: 0 $default-padding / 2;
        width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(3/5);
        max-width: percentage(3/5);
    }
}
.ac_content_4-5 {
    padding: 0 $default-padding / 2;
    width: 100%;
    @include breakpoint(medium) {
        flex: 0 1 percentage(4/5);
        max-width: percentage(4/5);
    }
}