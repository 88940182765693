
/* SECTION - MENU 2
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-3-transparent: false; 
$ac-menu-3-transparent-on-home: false;
$ac-menu-3-z-index: 2222;
$ac-menu-3-background: $default-background-light;
$ac-menu-3-gradient-before: false;
$ac-menu-3-gradient-before-color: $default-background-dark;
$ac-menu-3-logo-width: 140px; // height for medium up. 
$ac-menu-3-content-width: 1000px;
$ac-menu-3-divider-height: 30px;

$ac-menu-3-main-background: $default-background-light;
$ac-menu-3-main-content-secondary-menu-color: rgba($default-text-color, .5);

$ac-menu-3-bar-bottom-enabled: true;
$ac-menu-3-bar-bottom-justify-content: center;
$ac-menu-3-bar-bottom-background: $brand-light-gray;

//socials icon
$ac-menu-3-socials-icon-background: $default-icon-color-light;
$ac-menu-3-socials-icon-hover-background: $default-icon-color-dark;
$ac-menu-3-socials-icon-color: $default-icon-color-dark;
$ac-menu-3-socials-icon-hover-color: $default-icon-color-light;

// mobile
$ac-menu-3-mobile-fixed: true;  
$ac-menu-3-mobile-height: 70px;
$ac-menu-3-mobile-padding: 10px;
$ac-menu-3-mobile-svg-size: 25px;
$ac-menu-3-mobile-svg-color: $default-icon-color-dark;

// overlay
$ac-menu-3-overlay-container-background: $brand-lightest-gray;
$ac-menu-3-overlay-menu-primary-border: $default-border;
$ac-menu-3-overlay-menu-primary-text-align: center; // left or center
$ac-menu-3-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-3-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-3-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_3_globals';


/*--------------------------------------------------------------------------------------------*/


[data-m-type="menu-3"] {

}

