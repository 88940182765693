
/* MENU 1
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/

$ac-menu-1-transparent: false; 
$ac-menu-1-transparent-on-home: true;
$ac-menu-1-z-index: 2222;
$ac-menu-1-background: $default-background-light;
$ac-menu-1-gradient-before: false;
$ac-menu-4-gradient-before-color: $default-background-dark;
$ac-menu-1-logo-width: 180px; // height for medium up. 
$ac-menu-1-content-width: 1000px;
$ac-menu-1-divider-height: 40px;

$ac-menu-1-menu-primary-color: $default-text-color;
$ac-menu-1-menu-transparent-primary-color: $default-text-color-light;

$ac-menu-1-menu-secondary-color: rgba($default-text-color, .5);
$ac-menu-1-menu-secondary-divider-color: rgba($default-text-color, .1);
$ac-menu-1-menu-transparent-secondary-divider-color: rgba($default-text-color-light, .2);

//socials icon
$ac-menu-1-socials-icon-background: $default-icon-color-light;
$ac-menu-1-socials-icon-hover-background: $default-icon-color-dark;
$ac-menu-1-socials-icon-color: $default-icon-color-dark;
$ac-menu-1-socials-icon-hover-color: $default-icon-color-light;

// mobile
$ac-menu-1-mobile-fixed: true; 
$ac-menu-1-mobile-height: 70px;
$ac-menu-1-mobile-padding: 10px;
$ac-menu-1-mobile-svg-size: 25px;
$ac-menu-1-mobile-svg-color: $default-icon-color-dark;

// overlay
$ac-menu-1-overlay-container-background: $brand-lightest-gray;
$ac-menu-1-overlay-menu-primary-border: $default-border;
$ac-menu-1-overlay-menu-primary-text-align: center; // left or center
$ac-menu-1-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-1-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-1-overlay-backdrop-background: rgba($default-background-dark, .8);


@import '__menu_1_globals';


/*--------------------------------------------------------------------------------------------*/


[data-m-type="menu-1"] {

}

